import styled from '@emotion/styled';
import { LoadingOverlay } from '@mantine/core';

import { useDelayedValue } from '~/hooks/useDelayedValue';

interface Props {
  isFetching: boolean;
  isPreviousData: boolean;
  delay?: number;
  opacity?: number;
  loader?: boolean;
}

export function CustomLoadingOverlay({
  isFetching,
  isPreviousData,
  delay = 200,
  opacity = 0.4,
  loader = true,
}: Props) {
  const delayedIsFetching = useDelayedValue<boolean>(isFetching, delay);
  const delayedIsPreviousData = useDelayedValue<boolean>(isPreviousData, delay);
  return (
    <StyledOverlay
      visible={(isPreviousData || delayedIsPreviousData) && (isFetching || delayedIsFetching)}
      {...(!loader && { loader: <div /> })}
      transitionDuration={delay}
      exitTransitionDuration={delay + 100}
      rootopacity={opacity}
    />
  );
}

const StyledOverlay = styled(LoadingOverlay)<{ rootopacity: number }>`
  .mantine-Overlay-root {
    opacity: ${({ rootopacity }) => rootopacity};
  }
`;
