import type { SelectProps } from '@mantine/core';
import { Select, Loader } from '@mantine/core';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({ theme }) => `
  max-width: ${theme.other.layout.maxWidth}px;
  height: calc(100% - 72px);
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  padding: ${theme.other.layout.paddingLg}px;

  ${theme.fn.smallerThan('lg')} {
    padding: ${theme.other.layout.paddingMd}px;
  }
  ${theme.fn.smallerThan('md')} {
    padding: ${theme.other.layout.paddingSm}px;
  }
`
);

export const LobbyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  top: 0;
`;

export const LobbyWrapper = styled.div(
  ({ theme }) => `
  position: relative;
  margin: 0 -${theme.spacing.lg}px;
  flex-shrink: 0;
  overflow: auto;

  ${theme.fn.smallerThan('md')} {
    margin: 0 -${theme.spacing.sm}px;
  }
`
);
export const StyledLoader = styled(Loader)`
  color: ${({ theme }) => (theme.colorScheme === 'dark' ? theme.white : theme.black)};
`;

export const IconGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
`;

export const PaginationGroup = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;

  ${theme.fn.smallerThan('sm')} {
    flex-direction: column;
    gap: ${theme.spacing.md}px;
  }
`
);

export const StyledSelect = styled(Select)<SelectProps>`
  min-width: 120px;
`;
