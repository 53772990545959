import { Paper, Stack, Modal } from '@mantine/core';
import React from 'react';

import type { Channel } from '~/domains/commissioner/interfaces/channel.interface';

import List from './List/List';
import SearchInput from './SearchInput';
import useCommissionerSearchInput from './CommissionerSearchInput.hook';
import useStyles from './CommissionerSearchInput.styles';

type CommissionerSearchInputProps = {
  items?: Channel[];
  isLoading?: boolean;
  isError?: boolean;
  isFullscreen?: boolean;
  onSelect?: (commissionerId: string) => void;
  onChange?: (value: string) => void;
  selectedChannelName?: string;
  selectedCommissionerId?: string;
};

function CommissionerSearchInput({
  items,
  isLoading,
  isError,
  isFullscreen,
  onSelect,
  onChange,
  selectedChannelName,
  selectedCommissionerId,
}: CommissionerSearchInputProps) {
  const { classes } = useStyles();
  const {
    containerRef,
    handleClear,
    handleInputKeydown,
    handleSelect,
    handleBlur,
    handleClose,
    handleOpen,
    highlightedItemIndex,
    isOpen,
    itemsRefs,
    listRef,
    query,
    setQuery,
    modalContentRef,
    handleFullscreenInputBlurAndFocus,
  } = useCommissionerSearchInput({
    isFullscreen,
    items,
    onChange,
    onSelect,
    selectedChannelName,
    selectedCommissionerId,
  });

  return (
    <div className={classes.container} ref={containerRef}>
      <SearchInput
        value={query}
        onChange={setQuery}
        onFocus={handleOpen}
        onBlur={isFullscreen ? undefined : handleBlur}
        onClear={handleClear}
        onKeyDown={handleInputKeydown}
      />
      {isOpen &&
        (() => {
          if (isFullscreen) {
            return (
              <Modal
                fullScreen
                opened={isOpen}
                onClose={handleClose}
                withCloseButton={false}
                padding={0}
              >
                <div className={classes.modalContent} ref={modalContentRef}>
                  <div className={classes.modalInputWrapper}>
                    <SearchInput
                      value={query}
                      onBlur={handleFullscreenInputBlurAndFocus}
                      onFocus={handleFullscreenInputBlurAndFocus}
                      onChange={setQuery}
                      onClear={handleClear}
                      onKeyDown={handleInputKeydown}
                      isClearAlwaysVisible
                    />
                  </div>
                  <Stack spacing={2} className={classes.modalListWrapper} ref={listRef}>
                    <List
                      query={query}
                      items={items}
                      isLoading={isLoading}
                      isError={isError}
                      onSelect={handleSelect}
                      selectedCommissionerId={selectedCommissionerId}
                      highlightedItemIndex={highlightedItemIndex}
                      itemsRefs={itemsRefs}
                      isFullscreen={isFullscreen}
                    />
                  </Stack>
                </div>
              </Modal>
            );
          }

          return (
            <Paper className={classes.flyout} shadow="md" ref={listRef}>
              <Stack p={2} spacing={2}>
                <List
                  query={query}
                  items={items}
                  isLoading={isLoading}
                  isError={isError}
                  onSelect={handleSelect}
                  selectedCommissionerId={selectedCommissionerId}
                  highlightedItemIndex={highlightedItemIndex}
                  itemsRefs={itemsRefs}
                />
              </Stack>
            </Paper>
          );
        })()}
    </div>
  );
}

export default CommissionerSearchInput;
