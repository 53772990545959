import { useContext, useEffect } from 'react';

import {
  LocalStateDomain,
  useLocalStateWithURLQuery,
} from '~/domains/common/hooks/useLocalStateWithURLQuery';

import { ContestFilterContext } from './ContestFiltersContext';

export const useContestFiltersValue = <V extends boolean | string | number | Array<any>>(
  key: string,
  defaultValue?: V
): [V, (newValue: V) => void] => {
  const { setFilterValues } = useContext(ContestFilterContext);
  const [value, setValue] = useLocalStateWithURLQuery<V>(LocalStateDomain.LOBBY, key, defaultValue);

  useEffect(() => {
    setFilterValues((v) => {
      if (v[key] === value) return v;

      return { ...v, [key]: value };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, setFilterValues, JSON.stringify(value)]);

  return [value, setValue];
};
