import { Center, Pagination } from '@mantine/core';

import { CarouselWithCards } from '~/components/shared/CarouselWithCards/CarouselWithCards';
import { CustomLoadingOverlay } from '~/components/shared/Loaders/CustomLoadingOverlay';
import ContestFilterMenu from '~/components/shared/SelectMenu/ContestFilterMenu';
import GridLobby from '~/components/shared/Tables/TableLobby/GridLobby';
import TableLobby from '~/components/shared/Tables/TableLobby/TableLobby';
import { CarouselSpot } from '~/domains/cms/promo/carousel.contests/enums';
import LocationInfo from '~/domains/common/components/LocationInfo/LocationInfo';
import { LocationInfoContainer } from '~/domains/common/components/LocationInfo/useStyles';
import { ConnectedSignUpModal } from '~/domains/common/components/SignUpModal/ConnectedSignUpModal';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { ConnectedCarousel } from '~/domains/contest/domains/lobby/components/Carousel/ConnectedCarousel';
import dt from '~/testing';

import { ViewStyle } from './Lobby.enums';
import {
  LobbyHeader,
  LobbyWrapper,
  PaginationGroup,
  StyledLoader,
  StyledSelect,
  Wrapper,
} from './styles';
import { useLobby } from './useLobby';

function Lobby() {
  const isMobile = useIsMobile();

  const {
    isCommissionerSearchActive,

    isCMSCarouselEnabled,

    viewStyle,
    setLimit,
    page,
    pages,
    limit,

    data,
    error,
    isLoading,
    isPreviousData,
    isFetching,
    isInitialLoading,

    disallowedContestTypes,
    locationState,
    isJurisdictionInfoEnabled,
    isShowingAllContestTypes,

    handlePageChange,
  } = useLobby();

  return (
    <>
      <Wrapper>
        {isCMSCarouselEnabled ? (
          <ConnectedCarousel carouselSpot={CarouselSpot.LOBBY} />
        ) : (
          <CarouselWithCards />
        )}
        <LobbyHeader>
          <ContestFilterMenu />
          {isLoading && (
            <Center>
              <StyledLoader size="md" />
            </Center>
          )}
        </LobbyHeader>
        <LobbyWrapper>
          <div style={{ position: 'relative' }}>
            <CustomLoadingOverlay
              isFetching={isFetching}
              isPreviousData={isPreviousData}
              opacity={0.3}
              loader={false}
            />
            {(() => {
              if (isInitialLoading || error) {
                return null;
              }

              return (
                <>
                  {isJurisdictionInfoEnabled &&
                    !isShowingAllContestTypes &&
                    disallowedContestTypes.length > 0 && (
                      <LocationInfoContainer>
                        <LocationInfo
                          contestTypes={disallowedContestTypes}
                          locationState={locationState}
                          isInLobby
                        />
                      </LocationInfoContainer>
                    )}
                  {isMobile || viewStyle === ViewStyle.GRID ? (
                    <GridLobby data-test-id={dt.components.lobby.grid} data={data.data} />
                  ) : (
                    <TableLobby data-test-id={dt.components.lobby.table} data={data.data} />
                  )}
                </>
              );

              return;
            })()}
          </div>
        </LobbyWrapper>

        {!isLoading && !error && (
          <PaginationGroup>
            <Pagination
              page={page}
              total={pages}
              onChange={(value) => handlePageChange(value)}
              size="sm"
              position="center"
              radius="xs"
              noWrap
            />
            <StyledSelect
              value={limit.toString()}
              data={[
                { value: '10', label: '10 Results Per Page' },
                { value: '25', label: '25 Results Per Page' },
                { value: '50', label: '50 Results Per Page' },
                isCommissionerSearchActive
                  ? undefined
                  : { value: '100', label: '100 Results Per Page' },
              ].filter(Boolean)}
              variant="filled"
              onChange={(value) => {
                handlePageChange(1);
                return setLimit(parseInt(value, 10));
              }}
              radius="xs"
              size="sm"
            />
          </PaginationGroup>
        )}
      </Wrapper>
      <ConnectedSignUpModal />
    </>
  );
}

export default Lobby;
