import { BackgroundImage, Paper } from '@mantine/core';

import useContestPath from '~/common/hooks/useContestPath';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { getCurrentLocationForRef } from '~/domains/common/utils/getCurrentLocationForRef';

type CarouselCardProps = {
  image: string;
  contestId?: string;
};

function CarouselCard(
  {
    contestId,
    // status,
    image,
  }: CarouselCardProps /* & { status: ContestStatus } */
) {
  const contestPath = useContestPath({
    contestId,
    isAfterPickDeadline: false,
    query: `?ref=${getCurrentLocationForRef()}`,
  });

  return contestId ? (
    <UnstyledNextLink href={contestPath}>
      <Paper h={400}>
        <BackgroundImage src={image} h="100%" radius={16} />
      </Paper>
    </UnstyledNextLink>
  ) : (
    <Paper h={400}>
      <BackgroundImage src={image} h="100%" radius={16} />
    </Paper>
  );
}

export default CarouselCard;
