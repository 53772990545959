import { useCallback, useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';

import { useStateWithURLQuery } from './useStateWithURLQuery';
import { useLocalStorageSettings } from './useLocalStorageSettings';

export const enum LocalStateDomain {
  LOBBY = 'lobby',
  MY_ENTRIES = 'my-entries',
}

export const useLocalStateWithURLQuery = <V extends boolean | string | number | Array<any>>(
  domain: LocalStateDomain,
  key: string,
  defaultValue: V
): [V, (v: V) => void] => {
  const { user } = useContext(UserContext);
  const [localValue, setLocalValue] = useLocalStorageSettings(
    `${user?.id ?? 'anonymous'}@${domain}`,
    {
      [key]: defaultValue,
    }
  );
  const [queryValue, setQueryValue] = useStateWithURLQuery<V>(key);

  const value = queryValue || localValue[key] || defaultValue;

  const handleValueChange = useCallback(
    (newValue: V) => {
      setLocalValue((v) => ({ ...v, [key]: newValue }));
      setQueryValue(newValue);
    },
    [key, setLocalValue, setQueryValue]
  );

  return [value, handleValueChange];
};
