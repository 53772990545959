import { TextInput, useMantineTheme, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

type CommissionerSearchInputProps = {
  value: string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isClearAlwaysVisible?: boolean;
};

function CommissionerSearchInput({
  value,
  onChange,
  onFocus,
  onBlur,
  onClear,
  onKeyDown,
  isClearAlwaysVisible,
}: CommissionerSearchInputProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation('contest');

  return (
    <TextInput
      placeholder={t('lobby.commissionerSearchInput.placeholder')}
      radius="xl"
      rightSection={
        value.length || isClearAlwaysVisible ? (
          <ActionIcon variant="subtle" radius="xl" onClick={onClear}>
            <IconX size={24} color={theme.colors.gray[5]} />
          </ActionIcon>
        ) : (
          <IconSearch size={16} color={theme.colors.gray[5]} />
        )
      }
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      autoComplete="off"
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      size="md"
    />
  );
}

export default CommissionerSearchInput;
