import { Title, Text, Box } from '@mantine/core';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import type { Channel } from '~/domains/commissioner/interfaces/channel.interface';

import Item from '../Item/Item';
import ItemSkeleton from '../Item/ItemSkeleton';
import { MIN_QUERY_CHARS } from '../constants';

type ListProps = {
  highlightedItemIndex?: number;
  isError?: boolean;
  isLoading?: boolean;
  items?: Channel[];
  itemsRefs: React.MutableRefObject<Record<string, HTMLButtonElement>>;
  onSelect?: (commissionerId: string) => void;
  query: string;
  selectedCommissionerId?: string;
  isFullscreen?: boolean;
};

function List({
  highlightedItemIndex,
  isError,
  isLoading,
  items,
  itemsRefs,
  onSelect,
  query,
  selectedCommissionerId,
  isFullscreen,
}: ListProps) {
  const { t } = useTranslation('contest');

  if (query.length === 0) {
    return (
      <Box p="sm">
        <Text fz="sm">
          {t('lobby.commissionerSearchInput.list.minChars.zero', { minChars: MIN_QUERY_CHARS })}
        </Text>
      </Box>
    );
  }
  if (query.length < MIN_QUERY_CHARS) {
    return (
      <Box p="sm">
        <Text fz="sm">
          {t('lobby.commissionerSearchInput.list.minChars.more', {
            charsRemaining: MIN_QUERY_CHARS - query.length,
          })}
        </Text>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </>
    );
  }

  if (isError) {
    return (
      <Box p="sm">
        <Title order={5} fz="md">
          {t('lobby.commissionerSearchInput.list.error.title')}
        </Title>
        <Text fz="sm">{t('lobby.commissionerSearchInput.list.error.text')}</Text>
      </Box>
    );
  }

  if (!items?.length) {
    return (
      <Box p="sm">
        <Text fz="sm">{t('lobby.commissionerSearchInput.list.empty')}</Text>
      </Box>
    );
  }

  return (
    <>
      {items.map((item, index) => (
        <Item
          key={item.id}
          channel={item}
          onClick={onSelect}
          isActive={item.userId === selectedCommissionerId}
          isHighlighted={highlightedItemIndex === index}
          isFullscreen={isFullscreen}
          ref={(node: HTMLButtonElement) => {
            if (itemsRefs?.current) {
              itemsRefs.current[item.id] = node;
            }
          }}
        />
      ))}
    </>
  );
}

export default List;
