import { useLocalStorage } from '@mantine/hooks';

export const getUseLocalStorageSettingsKey = (domain: string) => `splash::${domain}`;

export const useLocalStorageSettings = <T>(domain: string, defaultValue = {} as T) =>
  useLocalStorage<T>({
    key: getUseLocalStorageSettingsKey(domain),
    defaultValue,
    getInitialValueInEffect: false,
    serialize: JSON.stringify,
    deserialize: (str) => {
      if (str === 'undefined' || str === undefined) return defaultValue;
      return JSON.parse(str);
    },
  });
