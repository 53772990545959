import { useQuery } from '@tanstack/react-query';

import { getCommissionerChannelDataByUserId } from '../../../services/commissioner/commissioner-service.service.api';

const STALE_TIME = 30_000;

export const useCommissionerChannelByUserId = ({ userId }: { userId: string }) =>
  useQuery({
    queryKey: ['commissioner/channel', userId],
    queryFn: () => getCommissionerChannelDataByUserId(userId),
    staleTime: STALE_TIME,
    enabled: !!userId,
  });
