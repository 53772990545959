import { useEffect, useState } from 'react';

export const useDelayedValue = <T,>(initialValue: T = null, delay = 300): T => {
  const [actualValue, setActualValue] = useState(initialValue);
  const [debounceValue, setDebounceValue] = useState(initialValue);
  useEffect(() => {
    if (actualValue) {
      setDebounceValue(actualValue);
      return;
    }
    setTimeout(() => {
      setDebounceValue(actualValue);
    }, delay);
  }, [actualValue, delay]);

  useEffect(() => {
    setActualValue(initialValue);
  }, [initialValue]);
  return debounceValue;
};
