import type { ContestSettings } from '@betterpool/api-types/contests-service';
import { Button, Flex, Stack, Text, Title } from '@mantine/core';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';

import { Routes } from '~/domains/common/constants/routes';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import { useIsCanada } from '../../hooks/useIsCanada';
import { Avatar } from '../Avatar/Avatar';
import { PrimaryButton } from '../Button/Button';
import { Link } from '../Link/Link';

type SignUpModalContentProps = {
  name: string;
  segments?: ContestSettings['segments'];
  avatar?: string;
};

export function SignUpModalContent({ avatar, segments, name }: SignUpModalContentProps) {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const isCanada = useIsCanada();

  return (
    <Stack align="center" spacing={isMobile ? 'md' : 'xl'}>
      <Avatar src={avatar} size={85} radius={85} />
      <Stack spacing={0} align="center">
        {segments ? (
          <Title order={3} ta="center">
            <Trans
              i18nKey="common:newToSplash.joinThisContestSeasonPayout"
              components={{
                br: isMobile ? <br /> : null,
                link: (
                  <Link
                    target="_blank"
                    href="https://splashsports.com/blog/in-season-payouts-are-live-on-splash"
                  />
                ),
              }}
            />
          </Title>
        ) : (
          <Title order={3}>{t('newToSplash.joinThisContest')}</Title>
        )}
        <Text color="gray.6">{name}</Text>
      </Stack>
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        gap="md"
        w="100%"
        maw={isMobile ? '100%' : 420}
      >
        <PrimaryButton
          component={NextLink}
          href={Routes.signUp({
            redirectTo: `${window.location.pathname}${window.location.search}`,
          })}
          size="md"
          fullWidth
        >
          {t('newToSplash.signUp')}
        </PrimaryButton>
        <Button
          component={NextLink}
          href={Routes.signIn({
            redirectTo: `${window.location.pathname}${window.location.search}`,
          })}
          variant="outline"
          color="dark"
          size="md"
          fullWidth
        >
          {t('newToSplash.logIn')}
        </Button>
      </Flex>
      <Text size="xs" align="center" maw={290}>
        <Trans
          i18nKey="common:newToSplash.termsAndPrivacy"
          components={{
            termsLink: <Link href={Routes.termsOfUse(isCanada)} target="_blank" />,
            privacyLink: <Link href={Routes.privacyPolicy(isCanada)} target="_blank" />,
            cookiesLink: (
              <Link href="https://legal.splashsports.com/legal/cookie-notice" target="_blank" />
            ),
          }}
        />
      </Text>
    </Stack>
  );
}
