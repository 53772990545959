import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ContestType } from '@betterpool/api-types/contests-service';

import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';

import { ContestFilterContext } from '../../context/ContestFiltersContext/ContestFiltersContext';
import { useContestFiltersValue } from '../../context/ContestFiltersContext/useContestFiltersValue';

export type ContestTypeValue = ContestType | 'all';

const AllContestTypes: ContestTypeValue[] = [
  ContestType.TEAM_SURVIVOR,
  ContestType.TEAM_PICK_EM,
  ContestType.PLAYER_TIER,
  // ContestType.PLAYER_CATEGORY, // Not supported yet
  ContestType.PLAYER_ONE_AND_DONE,
];

function isContestType(value: ContestType): value is ContestType {
  return !Array.isArray(value) && AllContestTypes.includes(value as ContestType);
}

const getTabValueFromContestTypes = (
  value?: ContestType[] | ContestType
): (typeof AllContestTypes)[number] => {
  const contestTypes = Array.isArray(value) ? value : [value];

  if (!contestTypes?.length || !isContestType(contestTypes[0])) {
    return 'all';
  }
  return contestTypes[0];
};

const useFilterContestType = () => {
  const { filters } = useContext(ContestFilterContext);
  const [contestTypes, setContestTypes] = useContestFiltersValue<ContestType | ContestType[]>(
    URLQueryKeys.CONTEST_TYPE
  );
  const selectedTab = getTabValueFromContestTypes(contestTypes);

  const tabList = useMemo(() => {
    if (!filters?.contestTypes) {
      return AllContestTypes;
    }

    return filters.contestTypes;
  }, [filters?.contestTypes]);

  const handleSetContestTypeFilter = useCallback(
    (contestTab: ContestTypeValue) => {
      if (contestTab === 'all') {
        // Return is necessary to override the query and prevent reporting the event
        return setContestTypes([]);
      }

      setContestTypes([contestTab]);
    },
    [setContestTypes]
  );

  useEffect(() => {
    if (selectedTab === 'all') {
      return;
    }

    if (!filters?.contestTypes?.length || filters?.contestTypes?.includes(selectedTab)) {
      return;
    }

    setContestTypes([]);
  }, [filters?.contestTypes, selectedTab, setContestTypes]);

  return {
    selectedTab,
    handleSetContestTypeFilter,
    tabList,
  };
};

export default useFilterContestType;
