import type { UseQueryResult } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

const getPagesTotal = <D>(queryResult: UseQueryResult<PaginatedData<D>>) =>
  Math.ceil(queryResult.data.total / queryResult.data.limit);

export const usePagesTotal = <D>(queryResult: UseQueryResult<PaginatedData<D>>) => {
  const [pagesTotal, setPagesTotal] = useState(queryResult.data ? getPagesTotal(queryResult) : 1);

  useEffect(() => {
    if (queryResult.data) {
      setPagesTotal(getPagesTotal(queryResult));
    }
  }, [queryResult, queryResult.data]);

  return pagesTotal;
};
