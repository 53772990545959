import { createStyles } from '@mantine/core';

const useFilterSportStyles = createStyles((theme) => ({
  wrapper: {
    gap: theme.spacing.xl,
    padding: `0 ${theme.spacing.md}px`,
    maxWidth: '100%',
    overflow: 'auto',
    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'space-between',
      gap: theme.spacing.xs,
      padding: `0 ${theme.spacing.xs}px`,
      marginLeft: -theme.spacing.xs,
      marginRight: -theme.spacing.xs,
      maxWidth: '100vw',
    },
  },
  icon: {
    cursor: 'pointer',
    border: theme.colorScheme === 'dark' ? theme.white : theme.black,
    padding: theme.spacing.md / 3,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    '&.active': {
      color: theme.colorScheme === 'dark' ? theme.black : theme.white,
      backgroundColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    '&.disabled': {
      stroke: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.dark[1],
      pointerEvents: 'none',
      cursor: 'none',
    },
  },
  text: {
    color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.dark[3],

    '&.disabled': {
      color: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.dark[1],
      pointerEvents: 'none',
      cursor: 'none',
    },
  },
}));

export default useFilterSportStyles;
