import type { ContestFilter } from '~/services/contests/interfaces/contest-filters.interface';

import FilterMultiselect from './FilterMultiselect';
import FilterCurrencyRange from './FilterCurrencyRange';

export function FilterSwitch({ filter }: { filter: ContestFilter }) {
  switch (filter.type) {
    case 'multiselect': {
      return <FilterMultiselect {...filter} />;
    }
    case 'currency-range': {
      return <FilterCurrencyRange {...filter} />;
    }
    default: {
      console.warn('Unknown filter type', filter);
      return null;
    }
  }
}
