import { Button, useMantineTheme, Group, Skeleton } from '@mantine/core';
import { useContext, useMemo, useState } from 'react';
import { IconFilter } from '@tabler/icons-react';

import { FEATURE } from '~/common/enums/feature.enum';
import ViewStyleSwitcher from '~/components/lobby/ViewStyleSwitcher';
import FilterLeague from '~/domains/contest/domains/lobby/components/Filter/FilterLeague';
import FilterContestType from '~/domains/contest/domains/lobby/components/Filter/FilterContestType';
import CommissionerSearchInput from '~/domains/contest/domains/lobby/components/CommissionerSearchInput/ConnectedCommissionerSearchInput';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { ContestFilterContext } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/ContestFiltersContext';
import repeat from '~/common/utils/repeat';
import useIsUserAdmin from '~/domains/payments/hooks/useIsUserAdmin';

import { Wrapper, DropdownFilterWrapper, StyledFilterContainer, StyledBadge } from './styles';
import { FilterSwitch } from './Filter/FilterSwitch';
import { getColorScheme } from './utils';

function ContestFilterMenu() {
  const theme = useMantineTheme();
  const isAdmin = useIsUserAdmin();
  const {
    filters,
    isLoading,
    setCommissionerId,
    commissionerId,
    viewStyle,
    setViewStyle,
    isCommissionerSearchEnabled,
    hideUnlisted,
    setHideUnlisted,
  } = useContext(ContestFilterContext);

  const isMobile = useIsMobile();
  const isContestTypeFilterEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CONTEST_TYPE_FILTER);
  const isLobbyToggleViewButtonsEnabled = useIsFeatureEnabled(
    FEATURE.SHOW_LOBBY_TOGGLE_VIEW_BUTTONS
  );
  const isLobbyToggleViewButtonsVisible = isLobbyToggleViewButtonsEnabled && !isMobile;

  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const filtersElements = useMemo(() => {
    if (isLoading) {
      return repeat(3, (index) => (
        <Skeleton key={index} height={44} width={isMobile ? '100%' : 180} radius="xl" />
      ));
    }
    if (!filters) {
      return [];
    }

    return filters.filters.map((filter) => (
      <FilterSwitch key={filter.requestKey} filter={filter} />
    ));
  }, [filters, isLoading, isMobile]);

  const moreFilters = (
    <DropdownFilterWrapper isMobile={isMobile}>
      {filtersElements}
      {isAdmin && (
        <StyledBadge
          variant="filled"
          color={getColorScheme(theme.colorScheme, false)}
          radius="xl"
          px="md"
          onClick={() => {
            setHideUnlisted(!hideUnlisted);
          }}
        >
          {hideUnlisted ? 'Show unlisted' : 'Hide unlisted'}
        </StyledBadge>
      )}
    </DropdownFilterWrapper>
  );

  return (
    <>
      <Group position="apart" align="center">
        <FilterLeague isDisabled={!!commissionerId} />
        {isLobbyToggleViewButtonsVisible && (
          <ViewStyleSwitcher value={viewStyle} onChange={setViewStyle} />
        )}
      </Group>
      {isContestTypeFilterEnabled && <FilterContestType isDisabled={!!commissionerId?.length} />}

      <StyledFilterContainer>
        <Wrapper>
          {isCommissionerSearchEnabled ? (
            <CommissionerSearchInput
              isFullscreen={isMobile}
              onSelect={setCommissionerId}
              selectedCommissionerId={commissionerId}
            />
          ) : null}
          {isMobile && !commissionerId && (
            <Button
              variant="subtle"
              onClick={() => setShowMoreFilters(!showMoreFilters)}
              color="dark"
            >
              <IconFilter color={theme.colors.gray[5]} />
              &nbsp; More Filters
            </Button>
          )}
          {!isMobile && !commissionerId && moreFilters}
        </Wrapper>
        {/* Rendering the same components, but at a different spot in the DOM, outside of the flexbox */}
        {isMobile && showMoreFilters && !commissionerId && moreFilters}
      </StyledFilterContainer>
    </>
  );
}

export default ContestFilterMenu;
