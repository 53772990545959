import { Tabs } from '@mantine/core';
import styled from '@emotion/styled';

import { isDarkMode } from '~/config/themeUtils';

const StyledTabList = styled(Tabs.List)`
  position: relative;
  border-bottom: none;
  padding-bottom: 3px;
  gap: 40px;
  flex-wrap: nowrap;
  overflow-x: auto;

  &:after {
    position: absolute;
    content: '';
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #eee;
    z-index: 1;
  }
`;

const StyledTab = styled(Tabs.Tab)`
  font-size: 17px;
  font-weight: 600;
  position: relative;
  z-index: 2;
  flex-shrink: 0;

  @media (max-width: 480px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  &:focus {
    z-index: 2;
  }

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => (isDarkMode(theme) ? 'white' : theme.colors.dark[4])};
  }

  &[data-active] {
    color: ${({ theme }) => (isDarkMode(theme) ? theme.colors.splash[3] : 'inherit')};
  }
`;

export { StyledTabList, StyledTab };
