/* eslint-disable multiline-ternary */
import { ActionIcon, Popover, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';

import DualSlider from '~/components/shared/Sliders/DualSlider/DualSlider';
import type { ContestFilterCurrencyRange } from '~/services/contests/interfaces/contest-filters.interface';
import { useContestFiltersValue } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/useContestFiltersValue';

import { StyledPopover, StyledRangeBadge } from '../styles';
import { getColorScheme } from '../utils';

type FilterCurrencyRangeProps = ContestFilterCurrencyRange;

function FilterCurrencyRange({ title, options, requestKey }: FilterCurrencyRangeProps) {
  const [value, setValue] = useContestFiltersValue<[number, number]>(requestKey, null);

  const theme = useMantineTheme();
  const defaultMin = options.min.value / 100;
  const defaultMax = options.max.value / 100;

  const handleRemove = () => {
    setValue([defaultMin, defaultMax]);
  };
  const [min, max] = value ?? [defaultMin, defaultMax];

  const isInitialValues = min === defaultMin && max === defaultMax;
  return (
    <StyledPopover shadow="md" width="240px" radius="sm" withinPortal>
      <Popover.Target>
        <StyledRangeBadge
          variant={isInitialValues ? 'filled' : 'outline'}
          color={getColorScheme(theme.colorScheme, !isInitialValues)}
          radius="xl"
          rightSection={
            isInitialValues ? (
              <IconChevronDown size={16} />
            ) : (
              <ActionIcon size="sm" radius="xl" variant="filled" onClick={handleRemove}>
                <IconX size={10} />
              </ActionIcon>
            )
          }
        >
          {isInitialValues ? `All ${title}` : `$${min} - $${max}`}
        </StyledRangeBadge>
      </Popover.Target>

      <Popover.Dropdown p="32px 16px 16px">
        <DualSlider
          entryFee={[min, max]}
          setEntryFee={setValue}
          initialMin={defaultMin}
          initialMax={defaultMax}
        />
      </Popover.Dropdown>
    </StyledPopover>
  );
}

export default FilterCurrencyRange;
