/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/aria-role */
import { Checkbox, Popover, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';

import type { ContestFilterMultiselect } from '~/services/contests/interfaces/contest-filters.interface';
import { useContestFiltersValue } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/useContestFiltersValue';

import { StyledActionIcon, StyledBadge, StyledPopover } from '../styles';
import { getColorScheme } from '../utils';

type Props = ContestFilterMultiselect;

function FilterMultiselect({ options, requestKey, title }: Props) {
  const theme = useMantineTheme();
  const [values, setValues] = useContestFiltersValue<string[]>(requestKey, []);

  const handleOnChange = useCallback(
    (newValue: string) => {
      if (values.includes(newValue)) {
        setValues(values?.filter((selectedValue) => selectedValue !== newValue));
      } else {
        setValues([...values, newValue]);
      }
    },
    [setValues, values]
  );

  const handleRemove = () => {
    setValues([]);
  };

  const count = useMemo(
    () => options.reduce((acc, { value }) => (values.includes(value) ? acc + 1 : acc), 0),
    [values, options]
  );
  const allChecked = count === options.length;
  const indeterminate = count < options.length && count > 0;

  const items = useMemo(
    () =>
      options.map((option) => (
        <Checkbox
          role="maintine-checkbox"
          mt="md"
          ml={0}
          label={option.title}
          key={option.value}
          checked={values.includes(option.value)}
          onChange={() => handleOnChange(option.value)}
        />
      )),
    [handleOnChange, options, values]
  );

  const downIcon = <IconChevronDown size={16} />;

  const removeButton = (
    <div onClick={handleRemove} role="button">
      <StyledActionIcon size="sm" radius="xl" variant="filled">
        <IconX size={14} />
      </StyledActionIcon>
    </div>
  );
  return (
    <StyledPopover shadow="md" width="272px" radius="sm" withinPortal>
      <Popover.Target>
        <StyledBadge
          variant={count === 0 ? 'filled' : 'outline'}
          color={getColorScheme(theme.colorScheme, count > 0)}
          radius="xl"
          rightSection={indeterminate || allChecked ? removeButton : downIcon}
        >
          {!indeterminate
            ? allChecked
              ? `All ${title}  (${count})`
              : `All ${title} `
            : `${title} (${count})`}
        </StyledBadge>
      </Popover.Target>

      <Popover.Dropdown p="8px 16px 16px">{items}</Popover.Dropdown>
    </StyledPopover>
  );
}

export default FilterMultiselect;
