import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

import { usePagesTotal } from './usePagesTotal';
import { useItemsTotal } from './useItemsTotal';

type UsePaginatedQueryProps = {
  pageSize?: number;
  initialPage?: number;
};

export const usePaginatedQuery = <Data>(
  queryOptions: Omit<UseQueryOptions, 'queryFn'> & {
    queryFn: (opts: { limit: number; offset: number }) => Promise<PaginatedData<Data>>;
  },
  { pageSize = 20, initialPage = 1 }: UsePaginatedQueryProps = {}
) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const limit = pageSize;
  const offset = (currentPage - 1) * pageSize;

  const result = useQuery<PaginatedData<Data>>({
    queryFn: () => queryOptions.queryFn({ limit, offset }),
    queryKey: [...queryOptions.queryKey, limit, offset] as QueryKey,
    enabled: queryOptions.enabled,
    staleTime: queryOptions.staleTime,
  });
  const pagesTotal = usePagesTotal(result);
  const itemsTotal = useItemsTotal(result);

  return {
    ...result,
    currentPage,
    pagesTotal,
    itemsTotal,
    onCurrentPageChange: setCurrentPage,
  };
};
