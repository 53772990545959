import type { UseQueryResult } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

export const useItemsTotal = <D>(queryResult: UseQueryResult<PaginatedData<D>>) => {
  const [itemsTotal, setItemsTotal] = useState(queryResult.data?.total);

  useEffect(() => {
    if (queryResult.data) {
      setItemsTotal(queryResult.data?.total);
    }
  }, [queryResult, queryResult.data]);

  return itemsTotal;
};
