import type { ParsedUrlQueryInput } from 'querystring';

import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';

export function getCurrentQuery() {
  // Using queryString because useRouter is stale for some unknown reason
  const query = queryString.parse(window.location.search);
  return query;
}

/**
 * This function doesn't handle concurrency very well,
 * due to race condition when reading & overwritting current URL query.
 * Tread carefully...
 */
export function useStateWithURLQuery<
  T extends
    | string
    | number
    | boolean
    | readonly string[]
    | readonly number[]
    | readonly boolean[]
    | readonly [number, number]
>(key: string, defaultValue?: T): [T, (value: T) => void] {
  const router = useRouter();
  const isInitialised = useRef(false);
  const [target, setter] = useState<T>(defaultValue);

  function decoratedSetter(setterValue: T) {
    const oldQuery = getCurrentQuery();

    const query: ParsedUrlQueryInput = {
      ...oldQuery,
      [key]: setterValue,
    };
    if (!setterValue) {
      delete query[key];
    }
    router.push({ query }, undefined, { shallow: true });

    return setter(setterValue);
  }

  // Load initial value(s) from URL query
  useEffect(() => {
    const query = getCurrentQuery();
    const queryValue = query[key];

    // Only fire once, when router query becomes available
    if (queryValue && !isInitialised.current) {
      // If default value is an array, but query value is not, convert to array first
      if (Array.isArray(defaultValue) && !Array.isArray(queryValue)) {
        setter([queryValue] as unknown as T);
      } else {
        setter(queryValue as T);
      }
      isInitialised.current = true;
    }
  }, []);

  return [target, decoratedSetter];
}
