import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from 'react-responsive';

import { FEATURE } from '~/common/enums/feature.enum';
import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import CarouselCard from './CarouselCard';

type CarouselItem = {
  image: string;
  contestId?: string;
  show: boolean;
};

export function CarouselWithCards() {
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const showDefaultCarouselCard = useIsFeatureEnabled(FEATURE.SHOW_DEFAULT_CAROUSEL_CARD);
  const showSecondCarouselCard = useIsFeatureEnabled(FEATURE.SHOW_SECOND_CAROUSEL_CARD);
  const showThirdCarouselCard = useIsFeatureEnabled(FEATURE.SHOW_THIRD_CAROUSEL_CARD);

  const getCarouselCardImage = (cardNumber) => {
    if (isMobile) {
      return getCdnUrl(`/images/banner/Banner-${cardNumber}-Mobile.png`);
    }
    if (isTablet) {
      return getCdnUrl(`/images/banner/Banner-${cardNumber}-Tablet.png`);
    }
    return getCdnUrl(`/images/banner/Banner-${cardNumber}-Desktop.png`);
  };

  const carouselCards: CarouselItem[] = [
    {
      image: getCarouselCardImage(0),
      show: showDefaultCarouselCard,
    },
    {
      image: getCarouselCardImage(1),
      contestId: process.env.NEXT_PUBLIC_CAROUSEL_CARD_1_CONTEST_ID,
      show: showSecondCarouselCard,
    },
    {
      image: getCarouselCardImage(2),
      contestId: process.env.NEXT_PUBLIC_CAROUSEL_CARD_2_CONTEST_ID,
      show: showThirdCarouselCard,
    },
  ];

  const slideCount = carouselCards.filter((count: any) => count.show).length;

  if (slideCount === 0) {
    return null;
  }

  return (
    <Carousel
      slideSize="100%"
      withIndicators={slideCount > 1}
      withControls={slideCount > 1 && !isTablet}
      loop
      slideGap="xl"
      align="start"
      height="auto"
      styles={{
        indicator: {
          width: 12,
          height: 12,
          transition: 'width 250ms ease',
          '&[data-active]': {
            width: 12,
          },
        },
      }}
    >
      {carouselCards.map(
        (item: CarouselItem) =>
          item.show && (
            <Carousel.Slide key={item.image}>
              <CarouselCard {...item} />
            </Carousel.Slide>
          )
      )}
    </Carousel>
  );
}
