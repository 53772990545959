import { FEATURE } from '~/common/enums/feature.enum';
import ContestCard from '~/domains/contest/domains/common/components/ContestCard/ContestCard';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import { CardContainer, CardGrid } from './styles';

interface GridLobbyProps {
  data: Contest[];
}

function GridLobby({ data }: GridLobbyProps) {
  const isShowContestThumbnailEnabled = useIsFeatureEnabled(FEATURE.SHOW_CONTEST_ITEM_THUMBNAIL);
  const isCountdownPillEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CONTEST_COUNTDOWN_TIMER);

  const columns = data.map((contest) => (
    <ContestCard
      key={contest.id}
      contest={contest}
      isContestThumbnailShown={isShowContestThumbnailEnabled}
      isNotAllowedInLocation={contest.is_allowed_in_location === false}
      isCountdownPillEnabled={isCountdownPillEnabled}
    />
  ));

  return (
    <CardContainer>
      <CardGrid>{columns.length > 0 ? columns : <div>Nothing found</div>}</CardGrid>
    </CardContainer>
  );
}

export default GridLobby;
