import type { PropsWithChildren } from 'react';
import { createContext, useMemo, useState } from 'react';
import { showNotification } from '@mantine/notifications';

import { ViewStyle } from '~/components/lobby/Lobby.enums';
import type { SortDirection } from '~/domains/common/enums/sort-direction.enum';
import {
  LocalStateDomain,
  useLocalStateWithURLQuery,
} from '~/domains/common/hooks/useLocalStateWithURLQuery';
import { useStateWithURLQuery } from '~/domains/common/hooks/useStateWithURLQuery';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';
import type { SortOptions } from '~/services/contests/enums/sort-options.enum';
import { useContestFilters } from '~/services/contests/hooks/useContestFilters';
import type { ContestFilters } from '~/services/contests/interfaces/contest-filters.interface';
import useIsUserAdmin from '~/domains/payments/hooks/useIsUserAdmin';

import { useContestSort } from './useContestSort';

type ContestFiltersContextValue = {
  league?: string;
  setLeague: (league: string) => void;

  filters?: ContestFilters;
  isLoading: boolean;
  isError?: boolean;

  filterValues: Record<string, any>;
  setFilterValues: (values: Record<string, any>) => void;

  sortBy?: SortOptions;
  sortByOrder?: SortDirection;

  viewStyle: ViewStyle;
  setViewStyle: (viewStyle: ViewStyle) => void;

  handleSortBy: (key: SortOptions) => void;
  resetSortBy: () => void;

  isCommissionerSearchEnabled?: boolean;
  commissionerId: string;
  setCommissionerId: (commissionerId: string) => void;

  hideUnlisted: boolean;
  setHideUnlisted: (hideUnlisted: boolean) => void;
};

export const ContestFilterContext = createContext<ContestFiltersContextValue>({
  league: null,
  setLeague: () => {},

  isLoading: true,

  filterValues: {},
  setFilterValues: () => {},

  handleSortBy: () => {},
  resetSortBy: () => {},

  commissionerId: '',
  setCommissionerId: () => '',

  viewStyle: ViewStyle.TABLE,
  setViewStyle: () => {},

  hideUnlisted: true,
  setHideUnlisted: () => {},
});

export function ContestFilterProvider({
  children,
  isCommissionerSearchEnabled,
}: PropsWithChildren<{ isCommissionerSearchEnabled?: boolean }>) {
  const isAdmin = useIsUserAdmin();
  const [hideUnlisted, setHideUnlisted] = useState(!isAdmin);
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});
  const [league, setLeague] = useLocalStateWithURLQuery<string>(
    LocalStateDomain.LOBBY,
    URLQueryKeys.LEAGUE,
    null
  );

  const [viewStyle, setViewStyle] = useLocalStateWithURLQuery<ViewStyle>(
    LocalStateDomain.LOBBY,
    URLQueryKeys.VIEW,
    ViewStyle.TABLE
  );

  const [commissionerId, setCommissionerId] = useStateWithURLQuery(
    URLQueryKeys.COMMISSIONER_ID,
    ''
  );

  const { sortBy, sortByOrder, handleSortBy, resetSortBy } = useContestSort({ commissionerId });

  const filters = useContestFilters({
    leagueId: league,
    onError: (error) => {
      console.error('Error fetching contest filters', error);
      showNotification({
        title: 'Error fetching contest filters',
        message: 'Try refreshing the page',
        color: 'red',
      });
    },
  });

  const value = useMemo(
    () =>
      ({
        league,
        setLeague,

        filters: filters.data,
        isLoading: filters.isLoading,
        isError: filters.isError,

        filterValues,
        setFilterValues,

        sortBy,
        sortByOrder,
        handleSortBy,
        resetSortBy,

        viewStyle,
        setViewStyle,

        commissionerId,
        setCommissionerId,

        isCommissionerSearchEnabled,

        hideUnlisted,
        setHideUnlisted,
      }) satisfies ContestFiltersContextValue,
    [
      commissionerId,
      filterValues,
      filters.data,
      filters.isError,
      filters.isLoading,
      handleSortBy,
      hideUnlisted,
      isCommissionerSearchEnabled,
      league,
      resetSortBy,
      setCommissionerId,
      setLeague,
      setViewStyle,
      sortBy,
      sortByOrder,
      viewStyle,
    ]
  );

  return <ContestFilterContext.Provider value={value}>{children}</ContestFilterContext.Provider>;
}
