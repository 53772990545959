import type { ContestType } from '@betterpool/api-types/contests-service';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { reportEvent } from '~/domains/analytics';
import { usePaginatedQuery } from '~/domains/common/hooks/usePaginatedQuery';
import useIsUserAdmin from '~/domains/payments/hooks/useIsUserAdmin';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useLocationState } from '~/hooks/useLocationState';
import type { GetContestsPayload } from '~/services/contests/contests.service.api';
import { getContests } from '~/services/contests/contests.service.api';
import { ContestFilterContext } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/ContestFiltersContext';

import { useContestsByCommissionerId } from '../../domains/commissioner/hooks/useContestsByCommissionerId';

export const useLobby = () => {
  const [pageSize, setPageSize] = useState(50);

  const showLobbyToggleViewButtons = useIsFeatureEnabled(FEATURE.SHOW_LOBBY_TOGGLE_VIEW_BUTTONS);
  const isCMSCarouselEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CMS_CAROUSEL);
  const isJurisdictionInfoEnabled = useIsFeatureEnabled(FEATURE.JURISDICTION_ENABLE_INFO_LOBBY);
  const isFilterLeagueEnabled = useIsFeatureEnabled(FEATURE.ENABLE_FILTER_LEAGUE);
  const isAdmin = useIsUserAdmin();

  const locationState = useLocationState();

  // ------------------ DATA QUERY ------------------
  const initialPayload = {
    offset: 0,
    filter: {},
    includeFull: isAdmin,
  };

  const [payload, setPayload] =
    useState<Omit<GetContestsPayload, 'limit' | 'offset'>>(initialPayload);

  const allContests = usePaginatedQuery(
    {
      queryKey: ['contests', payload, locationState],
      keepPreviousData: true,
      queryFn: ({ limit, offset }) => getContests({ ...payload, limit, offset }),
    },
    {
      pageSize,
    }
  );

  // ------------------ FILTERING ------------------
  const {
    //
    league,
    filterValues,
    sortBy,
    sortByOrder,
    resetSortBy,
    commissionerId,
    viewStyle,
    hideUnlisted,
  } = useContext(ContestFilterContext);

  const commissionerContests = useContestsByCommissionerId({ userId: commissionerId });

  const {
    data,
    error,
    isLoading,
    isPreviousData,
    isFetching,
    isInitialLoading,
    pagesTotal,
    onCurrentPageChange,
    currentPage,
  } = commissionerId ? commissionerContests : allContests;

  // Analytics;
  useEffect(() => {
    const { filter } = payload;

    // Crude check to see if the default filter has been changed
    // 1. If the filter payload has more than 1 key (entryFee is always there)
    // 2. if the entryFee values are different than the default (currently hardcoded to 0 and 10000000)
    if (
      filter &&
      (Object.keys(filter).length > 1 ||
        filter.entryFee?.min > 0 ||
        filter.entryFee?.max < 10000000)
    ) {
      reportEvent('Lobby > Filter, Contest', {
        league_id: filter.leagueId,
        payout_formats: filter.payoutFormats,
        entry_types: filter.entryTypes,
        contest_type: filter.contestTypes,
        entry_fee: filter.entryFee ? [filter.entryFee.min, filter.entryFee.max] : null,
      });
    }
  }, [payload.filter]);

  // If any of the filters change, reset sort order to be default
  useEffect(() => {
    resetSortBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues)]);

  // Update Request Data to send to endpoint
  useEffect(() => {
    const payloadObj = cloneDeep(payload) as any;
    payloadObj.includeFull = isAdmin;
    if (isAdmin) {
      payloadObj.hideUnlisted = hideUnlisted;
    }

    // Set Filters
    const filter = Object.entries(cloneDeep(filterValues)).reduce(
      (acc, [key, value]) => {
        if (value === null || value === undefined || value === '' || value.length === 0) return acc;

        acc[key] = value;
        return acc;
      },
      {} as Record<string, any>
    );

    // API requires `sport` filter key, not plural `sports`
    // if we have a league, we don't need to filter by sport - this should also handle the case when the sport is stored in local storage
    if (league) filter.leagueId = league;

    // API requires plural `contestTypes` filter key, not singular `contestType`
    if (filter.contestType) {
      filter.contestTypes = Array.isArray(filter.contestType)
        ? filter.contestType
        : [filter.contestType];
      delete filter.contestType;
    }

    if (filter.entryFee) {
      const [min, max] = filter.entryFee;
      filter.entryFee = { min: min * 100, max: max * 100 };
    }

    if (sortBy && sortByOrder) {
      payloadObj.sort = sortBy;
      payloadObj.dir = sortByOrder.toLowerCase();
    }

    payloadObj.filter = filter;

    // End Filters
    if (!isEqual(payloadObj, payload)) {
      setPayload(payloadObj);
      onCurrentPageChange(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payload,
    sortBy,
    sortByOrder,
    resetSortBy,
    isAdmin,
    onCurrentPageChange,
    isFilterLeagueEnabled,
    league,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filterValues),
  ]);

  useEffect(() => {
    if (!commissionerId) return;

    if (pageSize === 100) {
      setPageSize(50);
    }
  }, [commissionerId, pageSize]);

  const disallowedContestTypes = useMemo<ContestType[]>(() => {
    if (!data?.data || !Array.isArray(data.data) || data.data.length === 0) return [];

    const disallowedSet = data.data.reduce((acc, contest) => {
      if (contest.is_allowed_in_location === false) {
        acc.add(contest.contest_type);
      }
      return acc;
    }, new Set<ContestType>());

    return Array.from(disallowedSet);
  }, [data]);

  const isShowingAllContestTypes = useMemo(
    () => Array.isArray(filterValues.contestType) && filterValues.contestTypes?.length === 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filterValues.contestType)]
  );

  // ------------------ HANDLERS ------------------
  const handlePageChange = useCallback(
    (newPage: number) => {
      reportEvent('Lobby > Pagination', {
        page_number: newPage,
        results_per_page: pageSize,
        goto_first: newPage === 1,
        goto_last: newPage === pagesTotal,
      });
      onCurrentPageChange(newPage);
    },
    [onCurrentPageChange, pageSize, pagesTotal]
  );

  return {
    showLobbyToggleViewButtons,
    isCMSCarouselEnabled,
    isCommissionerSearchActive: !!commissionerId,

    viewStyle,
    setLimit: setPageSize,
    page: currentPage,
    pages: pagesTotal,
    limit: pageSize,

    data,
    error,
    isLoading,
    isPreviousData,
    isFetching,
    isInitialLoading,

    disallowedContestTypes,
    locationState,
    isJurisdictionInfoEnabled,
    isShowingAllContestTypes,

    handlePageChange,
  };
};
