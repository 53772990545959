/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Tabs } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import useFilterContestType from './FilterContestType.hook';
import { StyledTabList, StyledTab } from './FilterContestType.styles';

type ContestTypeFilterProps = {
  isDisabled?: boolean;
};

function ContestTypeFilter({ isDisabled }: ContestTypeFilterProps) {
  const { t } = useTranslation('common');

  const { selectedTab, handleSetContestTypeFilter, tabList } = useFilterContestType();

  return (
    <Tabs value={selectedTab} onTabChange={handleSetContestTypeFilter} w="100%">
      <StyledTabList>
        <StyledTab value="all">{t(`filters.contestTypes.all`)}</StyledTab>
        {tabList
          ?.map((contestType) => (
            <StyledTab value={contestType} key={contestType} disabled={isDisabled}>
              {t(`filters.contestTypes.${contestType}`)}
            </StyledTab>
          ))
          .filter(Boolean)}
      </StyledTabList>
    </Tabs>
  );
}

export default ContestTypeFilter;
