import { Button, Flex, Skeleton, Text, Tooltip, clsx } from '@mantine/core';
import { useTheme } from '@emotion/react';

import dt from '~/testing';
import repeat from '~/common/utils/repeat';

import useFilterSportStyles from './FilterLeague.styles';
import { useFilterLeague } from './FilterLeague.hook';

type FilterSportProps = {
  isDisabled?: boolean;
};

function FilterLeague({ isDisabled }: FilterSportProps) {
  const theme = useTheme();
  const { classes } = useFilterSportStyles();
  const { leagueFilterData, league, handleSetLeagueFilter } = useFilterLeague({ isDisabled });

  return (
    <Flex className={classes.wrapper} data-test-id={dt.contestLobby.table.filter.sport.container}>
      {(() => {
        if (!leagueFilterData) {
          return repeat(5, () => <Skeleton h={64} w={40} />);
        }

        return leagueFilterData.map(({ Icon, value, label, tooltipLabel, isEnabled }) => {
          const isActive = league === value;
          return (
            <Tooltip
              key={value}
              label={tooltipLabel}
              withArrow
              withinPortal
              events={{ hover: true, focus: !isEnabled, touch: !isEnabled }}
            >
              <Button
                variant="subtle"
                p={0}
                h="auto"
                disabled={!isEnabled}
                bg="transparent !important"
                sx={{ border: 'none' }}
                onClick={() => {
                  handleSetLeagueFilter(value);
                }}
              >
                <Flex
                  gap={2}
                  direction="column"
                  align="center"
                  data-aria-disabled={!isEnabled}
                  data-test-id={dt.contestLobby.table.filter.sport.badge}
                >
                  <Icon
                    data-value={value}
                    className={clsx(
                      classes.icon,
                      isActive ? 'active' : '',
                      !isEnabled ? 'disabled' : ''
                    )}
                    size={theme.spacing.lg * 2}
                    stroke={1}
                  />
                  <Text
                    size="sm"
                    fw={500}
                    className={clsx(classes.text, !isEnabled ? 'disabled' : '')}
                  >
                    {label}
                  </Text>
                </Flex>
              </Button>
            </Tooltip>
          );
        });
      })()}
    </Flex>
  );
}

export default FilterLeague;
