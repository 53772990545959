import { useQuery } from '@tanstack/react-query';

import { ContestQueryKey } from '../constants';
import { getContestFiltersByLeagueId } from '../contests.service.api';

export const useContestFilters = ({
  leagueId,
  enabled,
  onError,
}: {
  leagueId: string;
  enabled?: boolean;
  onError?: (error: unknown) => void;
}) =>
  useQuery({
    queryKey: ContestQueryKey.FILTERS(leagueId),
    queryFn: () => getContestFiltersByLeagueId(leagueId),
    onError,
    enabled,
  });
