import { useCallback, useState } from 'react';

import { reportEvent } from '~/domains/analytics/events';
import { SortDirection } from '~/domains/common/enums/sort-direction.enum';
import type { SortOptions } from '~/services/contests/enums/sort-options.enum';

export const useContestSort = ({ commissionerId }: { commissionerId?: string }) => {
  const [sortBy, setSortBy] = useState<SortOptions>(null);
  const [sortByOrder, setSortByOrder] = useState<SortDirection>(null);

  const handleSortBy = useCallback(
    (key: SortOptions) => {
      if (commissionerId.length) {
        return;
      }
      // User clicks a different column to sort by than currently selected column
      if (sortBy !== key) {
        setSortBy(key);
        setSortByOrder(SortDirection.DESC);

        reportEvent('Lobby > Sort By', {
          sort_by: key,
          sort_direction: SortDirection.DESC,
        });
      } else {
        setSortByOrder((prevSortOrder) => {
          const newSortOrder =
            prevSortOrder === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;

          reportEvent('Lobby > Sort By', {
            sort_by: key,
            sort_direction: newSortOrder,
          });
          return newSortOrder;
        });
      }
    },
    [sortBy, commissionerId]
  );

  const resetSortBy = () => {
    setSortBy(null);
    setSortByOrder(null);
  };

  return {
    sortBy,
    sortByOrder,
    handleSortBy,
    resetSortBy,
  };
};
