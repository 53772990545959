// Styles are written in object syntax

import styled from '@emotion/styled';
import type { ActionIconProps, BadgeProps, PopoverProps } from '@mantine/core';
import { ActionIcon, Badge, Popover } from '@mantine/core';

import { setBlackOrWhiteText } from '../../../config/themeUtils';

export const StyledFilterContainer = styled.div``;

export const StyledPopover = styled(Popover)<PopoverProps>`
  cursor: pointer;
  user-select: none;
  padding: 21px;
  font-size: 16px;
  min-width: 100px;
`;

export const StyledActionIcon = styled(ActionIcon)<ActionIconProps>`
  color: ${({ theme }) => setBlackOrWhiteText(theme)};
`;

export const StyledBadge = styled(Badge)<BadgeProps & { onClick?: () => void }>`
  font-size: 16px;
  text-transform: capitalize;
  color: ${({ theme }) => (theme.colorScheme === 'dark' ? theme.white : theme.black)};
  flex-shrink: 1;
  height: 44px;
  padding: ${({ theme }) => `${theme.spacing.lg}px`};
  cursor: pointer;
`;

export const StyledRangeBadge = styled(Badge)<BadgeProps>`
  padding: 16px;
  font-size: 16px;
  text-transform: capitalize;
  color: ${({ theme }) => (theme.colorScheme === 'dark' ? theme.white : theme.black)};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;
export const DropdownFilterWrapper = styled.div<{ isMobile }>`
  display: flex;
  gap: 16px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  overflow: hidden;
  align-items: stretch;
`;
export const ControlWrapper = styled.div`
  color: lightgray;
  display: flex;
  font-size: 15px;
  gap: 16px;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 1;
`;
