import { useState, useCallback } from 'react';
import { createStyles, RangeSlider } from '@mantine/core';

import DollarInput from '~/components/shared/Inputs/DollarInput';
import { useDebouncedCallback } from '~/hooks/useDebouncedCallback';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    width: '100%',
    padding: '0px 16px',
  },
  numberGroup: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    gap: 24,
  },

  slider: {
    width: '100%',
  },

  thumb: {
    width: 16,
    height: 16,
  },

  track: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
  },
}));

interface DualSliderProps {
  initialMin?: number;
  initialMax?: number;
  entryFee: [number, number];
  setEntryFee: ([min, max]: [number, number]) => void;
}

export default function DualSlider({
  initialMin = 0,
  initialMax = 1000,
  entryFee,
  setEntryFee,
}: DualSliderProps) {
  const { classes } = useStyles();

  const [startValue, setStartValue] = useState(Number.parseFloat(entryFee[0] as unknown as string));
  const [endValue, setEndValue] = useState(Number.parseFloat(entryFee[1] as unknown as string));

  const handleValueChange = useCallback(([_min, _max]: [number, number]) => {
    setStartValue(_min);
    setEndValue(_max);
  }, []);

  const handleSetMinValue = useCallback((minValue: number) => {
    if (minValue < endValue) {
      setStartValue(minValue);
      setEntryFee([minValue, endValue]);
    }
  }, []);

  const handleSetMaxValue = useCallback((maxValue: number) => {
    if (maxValue > startValue) {
      setEndValue(maxValue);
      setEntryFee([startValue, maxValue]);
    }
  }, []);

  const handleValueChangeEnd = useCallback(
    ([_min, _max]: [number, number]) => {
      setEntryFee([_min, _max]);
    },
    [startValue, endValue]
  );

  const debouncedHandleValueChange = useDebouncedCallback(handleValueChangeEnd);

  return (
    <div className={classes.wrapper}>
      <RangeSlider
        max={initialMax}
        step={5}
        min={initialMin}
        label={null}
        defaultValue={[initialMin, initialMax]}
        value={[startValue, endValue]}
        onChange={handleValueChange}
        onChangeEnd={debouncedHandleValueChange}
        size={2}
        radius={0}
        className={classes.slider}
        classNames={{ thumb: classes.thumb, track: classes.track }}
      />
      <div className={classes.numberGroup}>
        <DollarInput
          value={startValue}
          onChange={handleSetMinValue}
          label="$MIN"
          min={initialMin}
          max={entryFee[0]}
        />
        <DollarInput
          value={endValue}
          onChange={handleSetMaxValue}
          label="$MAX"
          min={entryFee[1]}
          max={initialMax}
        />
      </div>
    </div>
  );
}
