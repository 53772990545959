import styled from '@emotion/styled';
import { createStyles } from '@mantine/core';

type UseStylesProps = {
  isError: boolean;
};

const useStyles = createStyles((theme, { isError }: UseStylesProps) => ({
  paper: {
    borderColor: isError ? theme.colors.red[2] : theme.colors.orange[2],
    background: isError ? theme.colors.red[2] : theme.colors.orange[2],
  },
  content: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  button: {
    width: 'fit-content',
    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
}));

const LocationInfoContainer = styled.div(
  ({ theme }) => `
    position: relative;
    padding: 0 ${theme.spacing.lg}px;

    ${theme.fn.smallerThan('md')} {
      padding: ${theme.spacing.sm}px;
    }
`
);

export default useStyles;
export { LocationInfoContainer };
