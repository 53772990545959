import { Avatar, Group, Stack, Title, Text, createStyles, UnstyledButton } from '@mantine/core';
import React, { forwardRef } from 'react';

import { getPrimaryThemeColor } from '~/config/themeUtils';
import type { Channel } from '~/domains/commissioner/interfaces/channel.interface';

const useStyles = createStyles((theme, _params) => ({
  container: {
    position: 'relative',
    padding: `${theme.spacing.xs / 2}px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',
    cursor: 'pointer',

    '&:not(.isFullscreen)': {
      '&:not(.isActive)': {
        '&:hover, &.isHighlighted': {
          background: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[1],
        },
      },

      '&.isActive': {
        '&.isHighlighted:after': {
          content: '""',
          position: 'absolute',
          top: '2px',
          left: '2px',
          bottom: '2px',
          right: '2px',
          border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.white}`,
          borderRadius: '2px',
        },
      },
    },

    '&.isActive': {
      background: getPrimaryThemeColor(theme),
    },
  },
  title: {
    '&.isActive': {
      color: theme.black,
    },
  },
  subtitle: {
    '&.isActive': {
      color: theme.black,
    },
  },
}));

type ItemProps = {
  channel: Channel;
  isActive?: boolean;
  isHighlighted?: boolean;
  isFullscreen?: boolean;
  onClick?: (id: string) => void;
};

function Item({ channel, isActive, isHighlighted, isFullscreen, onClick }: ItemProps, ref) {
  const { classes, cx } = useStyles();

  return (
    <UnstyledButton
      onClick={() => {
        onClick?.(channel.userId);
      }}
      role="option"
      tabIndex={-1}
      className={cx(classes.container, { isActive, isHighlighted, isFullscreen })}
      ref={ref}
    >
      <Group spacing="xs" align="center">
        <Avatar size="md" radius="xl" src={channel.avatarUrl} />
        <Stack spacing={0}>
          <Title order={5} fw={400} fz="md" className={cx(classes.title, { isActive })}>
            {channel.name}
          </Title>
          <Text fz="xs" color="gray.6" className={cx(classes.subtitle, { isActive })}>
            {channel.username}
          </Text>
        </Stack>
      </Group>
    </UnstyledButton>
  );
}

export default forwardRef<HTMLButtonElement, ItemProps>(Item);
