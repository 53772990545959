import { useState } from 'react';

import { useCommissionersChannelsByQuery } from '~/domains/commissioner/hooks/useCommissionersChannelsByQuery';
import { useCommissionerChannelByUserId } from '~/domains/commissioner/hooks/useCommissionerChannelByUserId';

import CommissionerSearchInput from './CommissionerSearchInput';
import { MIN_QUERY_CHARS } from './constants';

type ConnectedCommissionerSearchInputProps = {
  isFullscreen?: boolean;
  onSelect?: (id: string | null) => void;
  selectedCommissionerId?: string;
};

function ConnectedCommissionerSearchInput({
  isFullscreen,
  onSelect,
  selectedCommissionerId,
}: ConnectedCommissionerSearchInputProps) {
  const [query, setQuery] = useState('');

  const channels = useCommissionersChannelsByQuery({
    query,
    enabled: query?.length >= MIN_QUERY_CHARS,
  });
  const selectedChannel = useCommissionerChannelByUserId({ userId: selectedCommissionerId });

  return (
    <CommissionerSearchInput
      items={channels.data?.data}
      isLoading={channels.isLoading}
      isError={channels.isError}
      onChange={setQuery}
      isFullscreen={isFullscreen}
      onSelect={onSelect}
      selectedChannelName={selectedChannel.data?.data?.name}
      selectedCommissionerId={selectedCommissionerId}
    />
  );
}

// TODO: figure out if deprecated
// eslint-disable-next-line import/no-unused-modules
export default ConnectedCommissionerSearchInput;
