import { useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { useSignUpModalContext } from '~/domains/common/context/SignUpModalContext';

import { SignUpModal } from './SignUpModal';

export function ConnectedSignUpModal() {
  const { user } = useContext(UserContext);
  const { isSignUpModalOpen, contestId, dispatch } = useSignUpModalContext();

  return !user ? (
    <SignUpModal
      contestId={contestId}
      isOpen={isSignUpModalOpen}
      onClose={() => dispatch({ type: 'closeSignUpModal' })}
    />
  ) : null;
}
