import type { NumberInputProps } from '@mantine/core';
import { NumberInput, createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  input: {
    height: 'auto',
    padding: 0,
    marginBottom: 16,
    textAlign: 'center',
    fontSize: 21,
  },
  label: {
    textAlign: 'center',
    width: '100%',
    fontSize: '11px',
    marginBottom: '4px',
  },
}));

const dollarsParser = (value: string) => value.replace(/\$\s?|(,*)/g, '');

const dollarsFormatter = (value: string) =>
  !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ ';

function DollarInput({
  value,
  onChange,
  label,
  min,
  max,
}: Pick<NumberInputProps, 'value' | 'onChange' | 'label' | 'min' | 'max'>) {
  const { classes } = useStyles();

  return (
    <NumberInput
      value={value}
      onChange={onChange}
      label={label}
      min={min}
      max={max}
      hideControls
      parser={dollarsParser}
      formatter={dollarsFormatter}
      classNames={{
        input: classes.input,
        label: classes.label,
      }}
    />
  );
}

export default DollarInput;
