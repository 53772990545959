import styled from '@emotion/styled';
import { Table, UnstyledButton } from '@mantine/core';
import { motion } from 'framer-motion';

export const ColumnTitle = styled.span`
  font-size: 17px;
  font-weight: 700;
  line-height: 1.2;
`;
export const ColumnSubTitle = styled.span`
  font-size: 13px;
`;

const BaseContainer = styled.div(
  ({ theme }) => `
    position: relative;
    padding: 0 ${theme.other.layout.paddingLg}px;

    ${theme.fn.smallerThan('lg')} {
      padding: ${theme.other.layout.paddingMd}px;
    }

    ${theme.fn.smallerThan('md')} {
      padding: ${theme.other.layout.paddingSm}px;
    }
`
);

export const TableContainer = styled(BaseContainer)(
  ({ theme }) => `
    ${theme.fn.smallerThan('md')} {
      width: fit-content;
    }
  `
);

export const CardContainer = styled(BaseContainer)(
  ({ theme }) => `
    ${theme.fn.smallerThan(320 + theme.spacing.md * 2)} {
      width: fit-content;
    },
  `
);

export const CardGrid = styled(motion.div)`
  overflow: visible;
  width: 100%;
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 16px;

  ${({ theme }) => theme.fn.smallerThan('sm')} {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => `${theme.other.layout.paddingSm}px`};
  }
`;

export const StyledRow = styled.tr``;

export const Column = styled.td``;

export const ColumnHeaderButton = styled(UnstyledButton)<{ onClick; children }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTable = styled(Table)`
  font-size: 15px;
`;

export const InnerColumn = styled.span`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;
