import { useCallback, useEffect, useRef, useState } from 'react';
import { useDisclosure, useScrollIntoView, useScrollLock } from '@mantine/hooks';

import type { Channel } from '~/domains/commissioner/interfaces/channel.interface';

type CommissionerSearchInputProps = {
  items?: Channel[];
  isFullscreen?: boolean;
  onSelect?: (commissionerId: string) => void;
  onChange?: (value: string) => void;
  selectedChannelName?: string;
  selectedCommissionerId?: string;
};

function useCommissionerSearchInput({
  items,
  isFullscreen,
  onSelect,
  onChange,
  selectedChannelName,
  selectedCommissionerId,
}: CommissionerSearchInputProps) {
  const containerRef = useRef<HTMLDivElement>();
  const itemsRefs = useRef<Record<string, HTMLButtonElement>>({});
  const modalContentRef = useRef<HTMLDivElement>();
  const [isOpen, handlers] = useDisclosure(false);
  const [query, setQuery] = useState('');
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView({
    duration: 0,
    offset: 5,
    cancelable: false,
    isList: true,
  });
  const [, setScrollLocked] = useScrollLock();
  useEffect(() => {
    onChange?.(query);
  }, [onChange, query]);
  useEffect(() => {
    if (selectedChannelName) {
      setQuery(selectedChannelName);
    }
  }, [selectedChannelName]);

  const scrollSelectedItemIntoView = useCallback(
    (index: number) => {
      setTimeout(() => {
        const itemByIndex = items?.[index];
        if (!itemByIndex) {
          return;
        }

        targetRef.current = itemsRefs.current[itemByIndex.id];
        scrollIntoView({ alignment: 'center' });
      }, 100);
    },
    [items, scrollIntoView, targetRef]
  );

  const handleOpen = useCallback(() => {
    if (isFullscreen) {
      setScrollLocked(true);
    }
    handlers.open();
  }, [handlers, isFullscreen, setScrollLocked]);

  const handleClose = useCallback(() => {
    setScrollLocked(false);
    handlers.close();
  }, [handlers, isFullscreen, setScrollLocked]);

  const handleSelect = useCallback(
    (id: string) => {
      const item = items?.find(({ userId }) => userId === id);
      onSelect?.(id);
      handleClose();
      if (!item) {
        return;
      }
      setQuery(item.name);
    },
    [handleClose, items, onSelect]
  );

  const handleClear = useCallback(
    (close = true) => {
      setQuery('');
      if (close) {
        handleClose();
      }
      onSelect?.(null);
    },
    [handleClose, onSelect]
  );

  const handleClickOutside = useCallback(
    (e) => {
      if (!containerRef.current) {
        return;
      }

      if (!containerRef.current?.contains(e.target)) {
        handleClose();
        document.removeEventListener('click', handleClickOutside);
      }
    },
    [handleClose]
  );

  const handleBlur = useCallback(
    (e) => {
      if (e.currentTarget.value) {
        return;
      }

      handleClear();
    },
    [handleClear]
  );

  useEffect(() => {
    if (isOpen && !isFullscreen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside, isFullscreen, isOpen]);

  const handleInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          setHighlightedItemIndex((v) => {
            const newIndex = v + 1 >= items?.length ? 0 : v + 1;
            scrollSelectedItemIntoView(newIndex);
            return newIndex;
          });
          break;
        }
        case 'ArrowUp': {
          event.preventDefault();
          setHighlightedItemIndex((v) => {
            const newIndex = v - 1 < 0 ? (items?.length ?? 0) - 1 : v - 1;
            scrollSelectedItemIntoView(newIndex);

            return newIndex;
          });
          break;
        }
        case 'Enter': {
          event.preventDefault();
          const value = event.currentTarget.value;
          if (!value) {
            handleClear();
            return;
          }

          const item = items?.[highlightedItemIndex];
          event.currentTarget?.blur();
          if (!item) {
            return;
          }
          handleSelect(item.userId);
          break;
        }
        case 'Escape': {
          event.preventDefault();
          handleClear(false);
          break;
        }
      }
    },
    [items, scrollSelectedItemIntoView, highlightedItemIndex, handleSelect, handleClear]
  );

  useEffect(() => {
    if (!selectedCommissionerId && items?.length) {
      setHighlightedItemIndex(-1);
      return;
    }
    if (!items?.length) {
      return;
    }
    const selectedItemIndex = items.findIndex((item) => item.userId === selectedCommissionerId);
    if (selectedItemIndex < 0) {
      return;
    }
    setHighlightedItemIndex(selectedItemIndex);
  }, [selectedCommissionerId, items]);

  const handleScreenResize = useCallback(() => {
    if (!modalContentRef.current) {
      return;
    }
    if (!window.visualViewport) {
      return;
    }

    modalContentRef.current.style.height = `${window.visualViewport.height}px`;
  }, []);

  const handleFullscreenInputBlurAndFocus = useCallback(() => {
    setTimeout(() => {
      handleScreenResize();
    }, 1000);
  }, [handleScreenResize]);

  useEffect(() => {
    if (!isFullscreen) {
      return;
    }
    if (!isOpen) {
      return;
    }

    window.addEventListener('resize', handleScreenResize);
    setTimeout(() => {
      handleScreenResize();
    }, 500);

    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize, isOpen, isFullscreen, query]);

  return {
    containerRef,
    query,
    setQuery,
    handleClose,
    handleOpen,
    handleClear,
    handleInputKeydown,
    isOpen,
    handleSelect,
    highlightedItemIndex,
    itemsRefs,
    listRef: scrollableRef,
    modalContentRef,
    handleBlur,
    handleFullscreenInputBlurAndFocus,
  };
}

export default useCommissionerSearchInput;
