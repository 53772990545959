import { ActionIcon, useMantineTheme } from '@mantine/core';
import React from 'react';
import { IconLayoutGrid, IconMenu } from '@tabler/icons-react';

import dt from '~/testing';

import { ViewStyle } from './Lobby.enums';
import { IconGroupWrapper } from './styles';

type ViewStyleSwitcherProps = {
  value: ViewStyle;
  onChange: (newViewStyle: ViewStyle) => void;
};

function ViewStyleSwitcher({ value, onChange }: ViewStyleSwitcherProps) {
  const { colorScheme } = useMantineTheme();

  const color = colorScheme === 'dark' ? 'dark.2' : 'dark.1';

  return (
    <IconGroupWrapper>
      <ActionIcon
        data-test-id={dt.components.lobby.header.toggle.table.view}
        size={32}
        color={color}
        variant={value === ViewStyle.TABLE ? 'filled' : 'outline'}
        onClick={() => {
          onChange(ViewStyle.TABLE);
        }}
      >
        <IconMenu />
      </ActionIcon>
      <ActionIcon
        data-test-id={dt.components.lobby.header.toggle.grid.view}
        size={32}
        color={color}
        variant={value === ViewStyle.GRID ? 'filled' : 'outline'}
        onClick={() => {
          onChange(ViewStyle.GRID);
        }}
      >
        <IconLayoutGrid />
      </ActionIcon>
    </IconGroupWrapper>
  );
}

export default ViewStyleSwitcher;
