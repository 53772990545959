import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    flexGrow: 1,
    maxWidth: 400,
    minWidth: 200,
  },
  flyout: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    marginTop: theme.spacing.xs / 2,
    zIndex: theme.other.zIndex.dropdownFlyout,
    minWidth: 400,
    maxHeight: 300,
    overflow: 'auto',

    ...(theme.colorScheme === 'dark'
      ? {
          border: `1px solid ${theme.colors.gray[8]}`,
        }
      : {}),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
  modalInputWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing.sm,
    zIndex: 1,
    backdropFilter: 'blur(2px)',
  },
  modalListWrapper: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing.sm,
    paddingTop: 54 + theme.spacing.sm * 2,
  },
}));

export default useStyles;
