import type { ColorScheme } from '@mantine/core';

const getColorScheme = (colorScheme: ColorScheme, isValues?: boolean) => {
  if (colorScheme === 'light') {
    return 'gray.4';
  }

  return isValues ? 'gray.5' : 'dark.5';
};

export { getColorScheme };
