import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';

export const gamesServiceInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}games-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(gamesServiceInstance);
handleRefreshInterceptor(gamesServiceInstance);

export const gamesServiceNoAuthInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}games-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});
