import { usePaginatedQuery } from '~/domains/common/hooks/usePaginatedQuery';

import { getContestsByCommissionerId } from '../../../services/contests/contests.service.api';

const STALE_TIME = 30_000;

export const useContestsByCommissionerId = ({ userId }: { userId: string }) =>
  usePaginatedQuery({
    queryKey: ['commissioner/contests', userId],
    queryFn: () => getContestsByCommissionerId({ commissionerId: userId }),
    staleTime: STALE_TIME,
    enabled: !!userId,
  });
