import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 10,
    height: 10,
    transition: 'width 250ms ease',
    '&[data-active]': {
      width: 20,
    },
    [theme.fn.smallerThan('sm')]: {
      width: 6,
      height: 6,
      '&[data-active]': {
        width: 12,
      },
    },
  },
  indicators: {
    [theme.fn.smallerThan('sm')]: {
      bottom: 8,
    },
  },
  slide: {
    width: '100%',
  },
}));

export default useStyles;
