import Cookies from 'js-cookie';
import { useMemo } from 'react';

import { jwtDecodeSafe } from '~/utils/jwt';

import { CognitoGroups } from './consts';

export const getIsUserAdmin = (accessToken: string = Cookies.get('accessToken')) => {
  if (!accessToken) {
    return false;
  }

  const jwt = jwtDecodeSafe(accessToken);
  const groups = jwt?.['cognito:groups'];

  return groups?.includes(CognitoGroups.SPLADMINS);
};

const useIsUserAdmin = () => {
  const isUserAdmin = useMemo(() => getIsUserAdmin(), []);

  return isUserAdmin;
};

export default useIsUserAdmin;
