import { usePaginatedQuery } from '~/domains/common/hooks/usePaginatedQuery';

import { getCommissionersChannelsByQuery } from '../../../services/commissioner/commissioner-service.service.api';

const STALE_TIME = 30_000;

export const useCommissionersChannelsByQuery = ({
  query,
  pageSize = 20,
  enabled = true,
}: {
  query: string;
  pageSize?: number;
  enabled?: boolean;
}) =>
  usePaginatedQuery(
    {
      queryKey: ['commissioner/channels/search', query],
      queryFn: ({ limit, offset }) => getCommissionersChannelsByQuery({ query, limit, offset }),
      staleTime: STALE_TIME,
      enabled,
    },
    {
      pageSize,
    }
  );
