type ContestRedirectArgs = {
  contestId: string;
  isAfterPickDeadline: boolean;
  query?: string;
};

const useContestPath = ({ contestId, isAfterPickDeadline, query = '' }: ContestRedirectArgs) => {
  if (!contestId) return;

  const gameURL = `/contest/${contestId}/game${query}`;
  const detailURL = `/contest/${contestId}/detail${query}`;

  return !isAfterPickDeadline ? detailURL : gameURL;
};

export default useContestPath;
