import { Group, Stack, createStyles, Skeleton } from '@mantine/core';
import React from 'react';

const useStyles = createStyles((theme) => ({
  container: {
    padding: `${theme.spacing.xs / 2}px ${theme.spacing.xs}px`,
  },
}));

function ItemSkeleton() {
  const { classes } = useStyles();

  return (
    <Group spacing="xs" align="center" className={classes.container}>
      <Skeleton height={38} width={38} circle />
      <Stack spacing={0}>
        <Skeleton width={150} height={20} />
        {/* <Skeleton width={100} height={16} /> */}
      </Stack>
    </Group>
  );
}

export default ItemSkeleton;
