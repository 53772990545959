/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTheme } from '@emotion/react';
import { Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import type { CSSProperties } from 'react';
import { useContext, useEffect, useState } from 'react';

import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import useContestInvite from '~/domains/contest/domains/invite/hooks/useContestInvite';
import InviteModal from '~/domains/contest/domains/invite/components/InviteModal';
import { SortOptions } from '~/services/contests/enums/sort-options.enum';
import { ContestFilterContext } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/ContestFiltersContext';

import { StyledRow, StyledTable, ColumnHeaderButton, TableContainer } from './styles';
import TableLobbyRow from './TableLobbyRow';

interface SortableColumnHeaderProps {
  value: SortOptions;
  label: string;
  style?: CSSProperties;
}

function SortableColumnHeader({ value, label, style }: SortableColumnHeaderProps) {
  const { handleSortBy, sortByOrder, sortBy } = useContext(ContestFilterContext);

  const theme = useTheme();

  return (
    <th style={style}>
      <ColumnHeaderButton onClick={() => handleSortBy(value)}>
        <b>{label}</b>
        {sortBy === value && sortByOrder === 'desc' && (
          <IconChevronDown size={16} color={theme.fn.primaryColor()} />
        )}
        {sortBy === value && sortByOrder === 'asc' && (
          <IconChevronUp size={16} color={theme.fn.primaryColor()} />
        )}
      </ColumnHeaderButton>
    </th>
  );
}

interface TableLobbyProps {
  data: Contest[];
}

function TableLobby({ data }: TableLobbyProps) {
  const [sortedData, setSortedData] = useState<Contest[]>([]);
  const [inviteModalContestId, setInviteModalContestId] = useState<string>(null);

  const { isInviteCTAShown } = useContestInvite();

  useEffect(() => {
    if (data) {
      setSortedData(data);
    }
  }, [data]);

  const rows = sortedData.map((row) => (
    <TableLobbyRow
      key={row.id}
      contest={row}
      isInviteCTAShown={isInviteCTAShown}
      setInviteModalContestId={setInviteModalContestId}
    />
  ));

  const inviteContest = sortedData.find((contest) => contest.id === inviteModalContestId);

  return (
    <>
      <TableContainer>
        <StyledTable
          fontSize={15}
          horizontalSpacing="lg"
          verticalSpacing="lg"
          highlightOnHover
          withBorder
          withColumnBorders
          style={{ maxWidth: '100vw', overflow: 'auto' }}
        >
          <thead>
            <StyledRow>
              {/* Currently not available in the API <SortableColumnHeader value={'contest'} label={'Contest'} /> */}
              <th>Contest</th>
              <SortableColumnHeader value={SortOptions.ENTRIES_REMAINING} label="Entries" />
              <th>Details</th>
              <SortableColumnHeader value={SortOptions.TOTAL_PRIZES} label="Total Prizes" />
              <SortableColumnHeader
                value={SortOptions.ENTRY_DEADLINE_DATE}
                label="Entry Deadline"
              />
              <SortableColumnHeader
                style={{ width: '184px', maxWidth: '184px' }}
                value={SortOptions.ENTRY_FEE}
                label="Entry Fee"
              />
              <th style={{ width: '100px', maxWidth: '100px' }} />
              {isInviteCTAShown && <th style={{ width: '100px', maxWidth: '100px' }} />}
            </StyledRow>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={6}>
                  <Text weight={500} align="center">
                    Nothing found
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </TableContainer>
      <InviteModal
        opened={!!inviteModalContestId}
        onClose={() => setInviteModalContestId(null)}
        contestId={inviteModalContestId}
        contestName={inviteContest?.name}
      />
    </>
  );
}

export default TableLobby;
