import type { ComponentProps } from 'react';
import { useContext, useCallback, useMemo } from 'react';
import {
  IconBallAmericanFootball,
  IconGolf,
  IconIceSkating,
  IconBallFootball,
  IconBallBasketball,
  IconBallBaseball,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { SportTag } from '~/domains/common/enums/sport-tag.enum';
import { getLeagues } from '~/services/games-service/games-service.service.api';

import { ContestFilterContext } from '../../context/ContestFiltersContext/ContestFiltersContext';

const createIconMapping = <T extends React.JSXElementConstructor<any>>(IconComponent: T) =>
  function Icon(props: ComponentProps<T>) {
    return <IconComponent {...props} />;
  };

const SportsIconMap = {
  [SportTag.GOLF]: createIconMapping(IconGolf),
  [SportTag.FOOTBALL]: createIconMapping(IconBallAmericanFootball),
  [SportTag.SOCCER]: createIconMapping(IconBallFootball),
  [SportTag.BASKETBALL]: createIconMapping(IconBallBasketball),
  [SportTag.BASEBALL]: createIconMapping(IconBallBaseball),
  [SportTag.HOCKEY]: createIconMapping(IconIceSkating),
} as const;

export const useFilterLeague = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { league, setLeague } = useContext(ContestFilterContext);

  const { data } = useQuery({
    queryKey: ['leagues'],
    queryFn: () => getLeagues(),
  });

  const handleSetLeagueFilter = useCallback(
    (leagueFilterValue: string) => {
      if (league === leagueFilterValue) {
        setLeague(null);
      } else {
        setLeague(leagueFilterValue);
      }
    },
    [league, setLeague]
  );

  const leagueFilterData = useMemo(
    () =>
      data?.map((l) => ({
        label: l.name,
        tooltipLabel: l.name,
        Icon: SportsIconMap[l.sport],
        value: l.id,
        isEnabled: !isDisabled,
      })),
    [data, isDisabled]
  );

  return {
    leagueFilterData,
    league,
    handleSetLeagueFilter,
  };
};
