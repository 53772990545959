import { MantineProvider } from '@mantine/core';

import { FEATURE } from '~/common/enums/feature.enum';
import Lobby from '~/components/lobby/Lobby';
import { useTreatments } from '~/domains/common/hooks/useTreatments';
import { ContestFilterProvider } from '~/domains/contest/domains/lobby/context/ContestFiltersContext/ContestFiltersContext';

function LobbyPage() {
  const { isReady, treatments } = useTreatments([
    FEATURE.ENABLE_CONTEST_LOBBY_LIGHT_THEME,
    FEATURE.ENABLE_COMMISSIONER_SEARCH,
  ]);

  const isLightThemeEnabled =
    treatments[FEATURE.ENABLE_CONTEST_LOBBY_LIGHT_THEME]?.treatment === 'on';
  const isCommissionerSearchEnabled =
    treatments[FEATURE.ENABLE_COMMISSIONER_SEARCH]?.treatment === 'on';

  if (!isReady) {
    return null;
  }

  return (
    // TODO: Remove when default theme will be light
    <MantineProvider inherit theme={{ colorScheme: isLightThemeEnabled ? 'light' : 'dark' }}>
      <ContestFilterProvider isCommissionerSearchEnabled={isCommissionerSearchEnabled}>
        <Lobby />
      </ContestFilterProvider>
    </MantineProvider>
  );
}

export default LobbyPage;

LobbyPage.isPublic = true;
